const $ = require("jquery");

var angular = require('angular');

// JS is equivalent to the normal "bootstrap" package
// no need to set this to a variable, just require it
require('bootstrap-sass');
// or you can include specific pieces
// require('bootstrap-sass/javascripts/bootstrap/tooltip');
// require('bootstrap-sass/javascripts/bootstrap/popover');

require('angular-ui-bootstrap');
//require('angular-file-saver');
require('angular-sanitize');
//require('angular-animate');
require('ui-select');
//require('checklist-model');
//require('angular-xeditable');
//require("angular-drag-and-drop-lists");
//require('jquery-sticky');
//require('angular-read-more');
//require('angular-ui-tinymce');
//var tinymce = require('tinymce');
//tinymce.baseURL = '/build/tinymce'; /*overwiret global tynymce path for plugins*/
//require('bootstrap-datepicker');
//require('eonasdan-bootstrap-datetimepicker');
//require('angularjs-scroll-glue');       // https://github.com/Luegg/angularjs-scroll-glue

var toastr = require('toastr');
//
//require('select2');
//
//$.fn.select2.defaults.set("theme", "bootstrap");

//const routes = require('../../public_html/js/fos_js_routes.json');
//import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
//
//Routing.setRoutingData(routes);

// const Routing = require('./routing').default;

import Routing from './routing';

///**
// * Need to import (not require)
// * See: https://github.com/indrimuska/angular-moment-picker/issues/133
// */
//// import * as moment from 'moment/min/moment.min';
//import moment from 'moment'
//import 'moment/min/locales'
//global.moment = moment;

/**
 * 
 * https://knpuniversity.com/screencast/javascript-webpack/modularize-our-code#play
 */

var app = angular.module("app", [
    //"ui.router",
    "ui.bootstrap", //need to display rating into speaker list - admin
    'ui.select',
    //"oc.lazyLoad",
     "ngSanitize"
    //"ngResource",
    //'GesofUtility'
]);
// adjust this in twig
app.value('ASSETS_VERSION', '');
app.directive('appSpinner', function() {
    return {
        replace: true,
        template: '<img src="/images/spinner.gif" />'
    };
});

//require('angular-google-chart');
//app.requires.push('googlechart');

global.$ = global.jQuery = $;
global.Routing = Routing;
global.app = app;
global.toastr = toastr;

//require('angular-ui-grid');
//require('angular-ui-tree');
